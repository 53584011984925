import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/HomeView.vue";
import DemoProgressBar from "@/views/DemoProgressBar.vue";
import DemoModal from "@/views/DemoModal.vue";
import DemoTabs from "@/views/DemoTabs.vue";
import DemoBreadcrumb from "@/views/DemoBreadcrumb.vue";
import DemoDropdown from "@/views/DemoDropdown.vue";
import DemoSkeleton from "@/views/DemoSkeleton.vue";
import DemoNavbar from "@/views/DemoNavbar.vue";
import DemoTable from "@/views/DemoTable.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/progressbar",
    name: "Progress bar demo",
    component: DemoProgressBar,
  },
  {
    path: "/modal",
    name: "Modal demo",
    component: DemoModal,
  },
  {
    path: "/tabs",
    name: "Tabs demo",
    component: DemoTabs,
  },
  {
    path: "/breadcrumb",
    name: "Breadcrumb demo",
    component: DemoBreadcrumb,
  },
  {
    path: "/dropdown",
    name: "Dropdown demo",
    component: DemoDropdown,
  },
  {
    path: "/skeleton",
    name: "Skeleton demo",
    component: DemoSkeleton,
  },
  {
    path: "/navbar",
    name: "Navbar demo",
    component: DemoNavbar,
  },
  {
    path: "/table",
    name: "Table demo",
    component: DemoTable,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
