<template>
  <li class="nav-item">
    <a class="nav-link" aria-current="page" :href="href"><slot /></a>
  </li>
</template>

<script>
export default {
  name: "b-nav-item",
  props: {
    href: String,
  },
};
</script>
