<template>
  <div style="display: flex; align-items: center; gap: 10px">
    <input class="form-check-input" type="checkbox" v-model="striped" />Striped
    <input class="form-check-input" type="checkbox" v-model="hover" />Hover
    <input class="form-check-input" type="checkbox" v-model="small" />Small
    <button class="btn btn-primary" @click="addItem">Add Item</button>
    <button class="btn btn-primary" @click="changeLabel">
      Change First field label
    </button>
  </div>
  <br />
  <br />
  <div style="display: flex; align-items: center; gap: 10px">
    <b-table
      :striped="striped"
      :hover="hover"
      :small="small"
      :fields="fields"
      :items="items"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import BTable from "@/components/BTable.vue";

export default {
  components: { BTable },
  setup() {
    const striped = ref(true);
    const hover = ref(true);
    const small = ref(false);
    const fields = ref([
      {
        key: "last_name",
        sortable: true,
      },
      {
        key: "first_name",
        sortable: false,
      },
      {
        key: "age",
        label: "Person age",
        sortable: true,
        // Variant applies to the whole column, including the header and footer
        variant: "danger",
      },
    ]);
    const items = ref([
      {
        isActive: true,
        age: 40,
        first_name: "Dickerson",
        last_name: "Macdonald",
      },
      {
        isActive: false,
        age: 21,
        first_name: "Larsen",
        last_name: "Shaw",
      },
      {
        isActive: false,
        age: 89,
        first_name: "Geneva",
        last_name: "Wilson",
      },
      {
        isActive: true,
        age: 38,
        first_name: "Jami",
        last_name: "Carney",
      },
    ]);
    return { striped, hover, small, fields, items };
  },
  methods: {
    addItem() {
      let firstName = Math.random().toString(24).substring(2, 15);
      firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
      let lastName = Math.random().toString(36).substring(2, 15);
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

      this.items.push({
        isActive: false,
        age: Math.floor(Math.random() * 100),
        first_name: firstName,
        last_name: lastName,
      });
    },
    changeLabel() {
      this.fields[0].label = Math.random().toString(36).substring(2, 15);
    },
  },
};
</script>
