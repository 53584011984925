<template>
  <li class="nav-item">
    <router-link
      class="nav-link"
      aria-current="page"
      active-class="active"
      :to="to"
    >
      <slot />
    </router-link>
  </li>
</template>

<script>
import { RouterLink } from "vue-router";

export default {
  name: "b-nav-item",
  props: ["to"],
  components: { RouterLink },
};
</script>
