<template>
  <ul class="navbar-nav">
    <slot />
  </ul>
</template>

<script>
export default {
  name: "b-navbar-nav",
};
</script>
