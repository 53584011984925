<template>
  <slot name="loading" v-if="loading"></slot>
  <slot v-else></slot>
</template>

<script>
export default {
  props: { loading: Boolean },
};
</script>
