<template>
  <div
    :class="{
      'btn-group': true,
      dropdown: true,
      dropup: dropup,
      dropend: dropright,
      dropstart: dropleft,
    }"
  >
    <button
      v-if="split"
      id="dd"
      type="button"
      class="btn btn-secondary"
      :class="{
        'dropdown-toggle': !split,
      }"
      :data-toggle="!split ? 'dropdown' : ''"
      :aria-haspopup="true"
      :aria-expanded="false"
    >
      {{ variant }}
    </button>
    <button
      v-if="!split"
      id="dd"
      type="button"
      class="btn btn-secondary"
      :class="{
        'dropdown-toggle': true,
      }"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ variant }}
    </button>
    <button
      v-if="split"
      type="button"
      class="btn btn-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    ></button>
    <div
      :class="{
        'dropdown-menu': true,
      }"
      aria-labelledby="dd"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dropup: {
      type: Boolean,
      default: false,
    },
    dropright: {
      type: Boolean,
      default: false,
    },
    dropleft: {
      type: Boolean,
      default: false,
    },
    split: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    setup() {},
  },
};
</script>
