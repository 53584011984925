<template>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-nav>
      <b-nav-item href="#">Link</b-nav-item>
      <b-nav-item href="#">Link 2</b-nav-item>
    </b-navbar-nav>
  </b-navbar>

  <br />

  <b-navbar toggleable="sm" type="dark" variant="primary" title="Custom title">
    <b-navbar-nav>
      <b-nav-item href="#">Hello</b-nav-item>
      <b-nav-item href="#">Bonjour</b-nav-item>
      <b-nav-item href="#">Buongiorno</b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import BNavbar from "@/components/b-navbar/BNavbar.vue";
import BNavbarNav from "@/components/b-navbar/BNavbarNav.vue";
import BNavItem from "@/components/b-navbar/BNavItem.vue";
import { ref } from "vue";

export default {
  components: { BNavbar, BNavbarNav, BNavItem },
  setup() {
    const showModal = ref(false);

    return { showModal };
  },
};
</script>
