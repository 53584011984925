<template>
  <button @click="showModal = true">Launch centered modal</button>

  <b-modal
    id="modal-center"
    :display="showModal"
    centered
    title="This is the modal title"
    @modalClosed="showModal = false"
  >
    <p class="my-4">Vertically centered modal!</p>
  </b-modal>
</template>

<script>
import BModal from "@/components/BModal.vue";
import { ref } from "vue";

export default {
  components: { BModal },
  setup() {
    const showModal = ref(false);

    return { showModal };
  },
};
</script>
