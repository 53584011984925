<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li
        v-for="item in items"
        :key="item.text"
        class="breadcrumb-item"
        :class="{ active: item.active }"
      >
        <a :href="item.href">{{ item.text }}</a>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: { items: Array },
  setup() {},
};
</script>
