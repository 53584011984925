<template>
  <div>
    <b-navbar
      toggleable="sm"
      type="dark"
      variant="primary"
      title="VueJS Final Project"
    >
      <b-navbar-nav>
        <b-nav-router-link to="/">Home</b-nav-router-link>
        <b-nav-router-link to="/progressbar">Progress bar</b-nav-router-link>
        <b-nav-router-link to="/modal">Modal</b-nav-router-link>
        <b-nav-router-link to="/tabs">Tabs</b-nav-router-link>
        <b-nav-router-link to="/breadcrumb">Breadcrumb</b-nav-router-link>
        <b-nav-router-link to="/dropdown">Dropdown</b-nav-router-link>
        <b-nav-router-link to="/skeleton">Skeleton</b-nav-router-link>
        <b-nav-router-link to="/navbar">Navbar</b-nav-router-link>
        <b-nav-router-link to="/table">Table</b-nav-router-link>
      </b-navbar-nav>
    </b-navbar>
  </div>

  <div class="container" style="padding-top: 1rem">
    <router-view />
  </div>
</template>

<script>
import { ref } from "vue";
import BNavbar from "./components/b-navbar/BNavbar.vue";
import BNavbarNav from "./components/b-navbar/BNavbarNav.vue";
import BNavRouterLink from "./components/b-navbar/BNavRouterLink.vue";
import { RouterView } from "vue-router";

export default {
  name: "App",
  components: {
    BNavbar,
    BNavbarNav,
    BNavRouterLink,
    RouterView,
  },
  setup() {
    return { animate: ref(true) };
  },
};
</script>
