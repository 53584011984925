<template>
  <h1>Progress bar</h1>
  <p>Example of progress bars:</p>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      v-model="animated"
      id="animatedCheck"
    />
    <label class="form-check-label" for="animatedCheck"> Animated? </label>
  </div>

  <b-progress
    :value="25"
    variant="success"
    striped
    :animated="animated"
  ></b-progress>
  <b-progress
    :value="50"
    variant="info"
    striped
    :animated="animated"
    class="mt-2"
  ></b-progress>
  <b-progress
    :value="75"
    variant="warning"
    striped
    :animated="animated"
    class="mt-2"
  ></b-progress>
  <b-progress
    :value="100"
    variant="danger"
    :animated="animated"
    class="mt-3"
  ></b-progress>
</template>

<script>
import { ref } from "vue";
import BProgress from "@/components/BProgress.vue";

export default {
  components: { BProgress },
  setup() {
    const animated = ref(false);
    return { animated };
  },
};
</script>
