<template>
  <div>
    <b-tabs>
      <b-tab title="First" active><p>I'm the first tab</p></b-tab>
      <b-tab title="Second"><p>I'm the second tab</p></b-tab>
      <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
    </b-tabs>

    <b-tabs align="center">
      <b-tab title="First"><p>I'm the first tab</p></b-tab>
      <b-tab title="Second" active><p>I'm the second tab</p></b-tab>
      <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
    </b-tabs>

    <b-tabs align="end">
      <b-tab title="First"><p>I'm the first tab</p></b-tab>
      <b-tab title="Second" active><p>I'm the second tab</p></b-tab>
      <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
    </b-tabs>

    <b-tabs end>
      <b-tab title="First" active><p>I'm the first tab</p></b-tab>
      <b-tab title="Second"><p>I'm the second tab</p></b-tab>
      <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
    </b-tabs>

    <b-tabs card>
      <b-tab title="First" active><p>I'm the first tab</p></b-tab>
      <b-tab title="Second"><p>I'm the second tab</p></b-tab>
      <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
    </b-tabs>

    <b-tabs card end>
      <b-tab title="First"><p>I'm the first tab</p></b-tab>
      <b-tab title="Second" active><p>I'm the second tab</p></b-tab>
      <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import BTabs from "@/components/b-tabs/BTabs.vue";
import BTab from "@/components/b-tabs/BTab.vue";

export default {
  components: { BTabs, BTab },
  setup() {},
};
</script>
