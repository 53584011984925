<template>
  <table
    class="table"
    :class="{
      'table-striped': striped,
      'table-hover': hover,
      'table-sm': small,
    }"
  >
    <thead>
      <tr>
        <th
          v-for="field in fields"
          v-bind:key="field.key"
          v-bind:class="getClassForVariant(field.variant)"
        >
          <span v-if="field.sortable">
            <a href="#" @click.prevent="sortBy(field.key)">
              <th scope="col">{{ getHeader(field) }}</th>
            </a>
          </span>
          <span v-else>
            <th scope="col">{{ getHeader(field) }}</th>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in calculatedItems" v-bind:key="item.id">
        <td
          v-for="field in fields"
          v-bind:key="field.key"
          v-bind:class="getClassForVariant(field.variant)"
        >
          {{ item[field.key] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "b-table",
  props: {
    striped: Boolean,
    hover: Boolean,
    small: Boolean,
    fields: Array,
    items: Array,
  },
  data() {
    return {
      sortedBy: {
        key: null,
        asc: true,
      },
    };
  },
  computed: {
    calculatedItems() {
      const itemsCopy = this.items.slice();
      if (this.sortedBy.key === null) {
        return itemsCopy;
      }

      itemsCopy.sort((a, b) =>
        a[this.sortedBy.key] > b[this.sortedBy.key] ? 1 : -1
      );
      if (!this.sortedBy.asc) {
        itemsCopy.reverse();
      }
      return itemsCopy;
    },
  },
  methods: {
    getHeader(field) {
      if (field.label) return field.label;
      return field.key
        .replace(/_/g, " ")
        .replace(/\b[a-z]/g, function (letter) {
          return letter.toUpperCase();
        });
    },
    getClassForVariant(variant) {
      if (!variant) return "";
      return "table-" + variant;
    },
    sortBy(key) {
      if (this.sortedBy?.key === key && this.sortedBy?.asc) {
        this.sortedBy = {
          key: key,
          asc: false,
        };
      } else {
        this.sortedBy = {
          key: key,
          asc: true,
        };
      }
    },
  },
};
</script>
