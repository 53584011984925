<template>
  <nav
    class="navbar"
    :class="[
      'bg-' + variant,
      'navbar-' + type,
      fixed ? 'fixed-' + fixed : '',
      toggleable ? 'navbar-expand-' + toggleable : 'navbar-expand',
    ]"
  >
    <div class="container-fluid">
      <a class="navbar-brand" href="#">{{ title }}</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <slot />
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "b-navbar",
  props: {
    fixed: String,
    sticky: Boolean,
    type: String,
    variant: { type: String, default: "primary" },
    toggleable: String,
    title: { type: String, default: "NavBar" },
  },
};
</script>
