<template>
  <div class="skeleton" :style="{ width }"></div>
</template>

<script>
export default {
  props: { width: String },
};
</script>

<style scoped>
.skeleton {
  background: lightgrey;
  height: 1rem;
  margin: 0.2rem 0;
  border-radius: 2px;
}
</style>
