<template>
  <div class="progress">
    <div
      class="progress-bar"
      :class="{
        'progress-bar-striped': striped,
        'progress-bar-animated': animated,
        'bg-success': variant === 'success',
        'bg-info': variant === 'info',
        'bg-warning': variant === 'warning',
        'bg-danger': variant === 'danger',
      }"
      role="progressbar"
      :style="{ width: value + '%' }"
      :aria-valuenow="value"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</template>

<script>
export default {
  name: "b-progress",
  props: {
    value: Number,
    animated: Boolean,
    variant: String,
    "show-progress": String,
    striped: Boolean,
  },
};
</script>
