<template>
  <div class="container" style="padding-top: 10rem; display: flex">
    <BDropdown variant="default dropdown">
      <BDropdownItem active>
        <span>active</span>
      </BDropdownItem>
      <BDropdownItem disabled>
        <span>disabled</span>
      </BDropdownItem>
      <BDropdownItem>
        <span>default</span>
      </BDropdownItem>
    </BDropdown>
    <div class="spacer" />
    <BDropdown variant="default dropdown with divider">
      <BDropdownItem active>
        <span>active</span>
      </BDropdownItem>
      <BDropdownDivider />
      <BDropdownItem disabled>
        <span>disabled</span>
      </BDropdownItem>
      <BDropdownItem>
        <span>default</span>
      </BDropdownItem>
    </BDropdown>
    <div class="spacer" />
    <BDropdown variant="dropright dropdown" dropright>
      <BDropdownItem active>
        <span>active</span>
      </BDropdownItem>
      <BDropdownItem disabled>
        <span>disabled</span>
      </BDropdownItem>
      <BDropdownItem>
        <span>default</span>
      </BDropdownItem>
    </BDropdown>
    <div class="spacer" />
    <BDropdown variant="dropleft dropdown" dropleft>
      <BDropdownItem active>
        <span>active</span>
      </BDropdownItem>
      <BDropdownItem disabled>
        <span>disabled</span>
      </BDropdownItem>
      <BDropdownItem>
        <span>default</span>
      </BDropdownItem>
    </BDropdown>
    <div class="spacer" />
    <BDropdown variant="dropup dropdown" dropup>
      <BDropdownItem active>
        <span>active</span>
      </BDropdownItem>
      <BDropdownItem disabled>
        <span>disabled</span>
      </BDropdownItem>
      <BDropdownItem>
        <span>default</span>
      </BDropdownItem>
    </BDropdown>
    <div class="spacer" />
    <BDropdown variant="split dropdown" split>
      <BDropdownItem active>
        <span>active</span>
      </BDropdownItem>
      <BDropdownItem disabled>
        <span>disabled</span>
      </BDropdownItem>
      <BDropdownItem>
        <span>default</span>
      </BDropdownItem>
    </BDropdown>
  </div>
</template>

<script>
import BDropdown from "@/components/b-dropdown/BDropdown.vue";
import BDropdownItem from "@/components/b-dropdown/BDropdownItem.vue";
import BDropdownDivider from "@/components/b-dropdown/BDropdownDivider.vue";

export default {
  components: { BDropdown, BDropdownItem, BDropdownDivider },
};
</script>
<style scoped>
.spacer {
  height: 1rem;
  width: 1rem;
}
</style>
