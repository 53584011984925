<template>
  <div ref="root" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button type="button" class="btn btn-primary">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, watchEffect, toRefs, ref } from "vue";

export default {
  props: {
    display: Boolean,
    centered: Boolean,
    title: String,
    size: String,
    hideHeader: Boolean,
    hideFooter: Boolean,
  },
  emits: ["modalClosed"],
  setup(props, { emit }) {
    const root = ref();
    const { display } = toRefs(props);

    onMounted(() => {
      /* global bootstrap */
      const modal = new bootstrap.Modal("#" + root.value.id);
      root.value.addEventListener("hidden.bs.modal", () => {
        emit("modalClosed");
      });
      watchEffect(() => {
        if (display.value) modal.show();
      });
    });

    return { root };
  },
};
</script>
