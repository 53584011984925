<template>
  <ul
    class="nav"
    :class="[
      'justify-content-' + align,
      end ? 'nav-fill' : '',
      card ? 'nav-pills' : 'nav-tabs',
    ]"
  >
    <li class="nav-item" v-for="header in tabHeaders" :key="header.title">
      <a
        class="nav-link"
        :class="{ active: header.active, disabled: header.disabled }"
        >{{ header.title }}</a
      >
    </li>
  </ul>
  <div class="tab-content">
    <slot />
  </div>
</template>

<script>
import { ref, provide } from "vue";

export default {
  name: "b-tabs",
  props: {
    vertical: Boolean,
    align: { type: String, default: "left" },
    card: Boolean,
    end: Boolean,
  },
  setup() {
    const tabHeaders = ref([]);
    provide("addTabHeader", (header) => {
      tabHeaders.value.push(header);
    });
    return { tabHeaders };
  },
};
</script>
