<template>
  <div class="tab-pane fade show active" v-if="active">
    <slot />
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "b-tab",
  props: {
    title: String,
    active: Boolean,
    disabled: Boolean,
  },
  setup(props) {
    const header = {
      title: props.title,
      active: props.active,
      disabled: props.disabled,
    };
    inject("addTabHeader")(header);
  },
};
</script>
