<template>
  <b-breadcrumb :items="items"></b-breadcrumb>
</template>

<script>
import { ref } from "vue";
import BBreadcrumb from "@/components/BBreadcrumb.vue";

export default {
  components: { BBreadcrumb },
  setup() {
    const items = ref([
      {
        text: "Admin",
        href: "#",
      },
      {
        text: "Manage",
        href: "#",
      },
      {
        text: "Library",
        active: true,
      },
    ]);
    return { items };
  },
};
</script>
